<template>
    <div class="content">
        <div class="form_section">
            <div class="section_name">Ссылки в навигации</div>
            <div class="form__item" style="flex-direction: row" v-for="(item, index) in this.navlinks" :key="index">

                <span>
                    <label class="item__name" for="inptName">Название:</label>
                    <input class="item__input" type="text" id="inptName" placeholder="Введите ссылку" v-model="item.name">
                </span>
                
                <span>
                    <label class="item__name" for="inptPath">Путь:</label>
                    <input style="opacity: .5" disabled class="item__input" type="text" placeholder="Введите ссылку" id="inptPath" v-model="this.navlinks[index].path">  
                </span>

            </div>

            <div class="form__item">
                <button type="submit" class="btn" v-on:click="saveNavLinks()">Сохранить</button>
            </div>
        </div>

    </div>
</template>

<script>
import action from '../services/main.js'

export default {
    name: "NavPage",
    data() {
        return {
            navlinks: []
        }
    },
    methods: {
        saveNavLinks() {
            action.saveNavLinks(JSON.stringify(this.navlinks)).then(res => {
                if(res.data.code == 200) {
                    this.$notify({
                        title: "Успешно",
                        text: res.data['message'],
                        duration: 2000,
                        type: 'success',
                    }); 
                }
                if(res.data.code == 401) {
                    const authStore = useAuthStore();

                    authStore.logout();
                    window.location.reload();
                }
            })
        }
    },
    mounted() {
        action.getNavLinks().then(res => {
            this.navlinks = res.data
        })
    }
}
</script>

<style scoped>
.form_section {
    margin-bottom: 20px;
}
.section_name {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
}
.content {
    max-width: 400px;
}
.form__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.item__name {
    font-weight: 400;
    font-size: 16px;
}
.item__input {
    background: #F8F8F8;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
}
.btn {
    padding: 10px 0;
    background: linear-gradient(95.41deg, #FFD8F6 0%, #FFD8E1 100%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.btn:hover {
    cursor: pointer;
}
</style>