<template>
    <div class="content__form">
        <div class="loader_wrp" v-bind:style="{ display: loaderDisplay }">
            <span class="loader"></span>
        </div>
        <table class="content__table">
            <tr class="table__line">
                <th class="row__name">
                    Название
                    <input type="text" v-model="searchQuery" placeholder="Введите название...">
                </th>
                <th class="row__name">Ссылка</th>
                <th class="row__name">Соц сети</th>
                <th class="row__name">Аккорды и текст песни</th>
                <th class="row__name">
                    <div class="psort">
                        <div class="n">Голос</div>
                        <svg v-show="this.sort" v-on:click="srt()" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33332 13.3333L16 20L22.6667 13.3333" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg v-show="!this.sort" v-on:click="srt()" id="" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.6667 18.6667L16 12L9.33334 18.6667" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </th>
                <th class="row__name">Статус</th>
            </tr>

            <tr v-if="!searchQuery" v-for="(item, index) in paginatedData" :key="index">
                <td>{{item.name}}</td> 
                <td style="display: flex; align-items: center; gap: 10px;">
                    <img class="video_img" :src="item.img" alt="">
                    <a class="link" :href="item.url">{{item.url}}</a>
                </td> 
                <td>
                    <div v-for="(url, index) in item.social_url" :key="index" style="max-width: 100px; overflow: hidden">
                        <a class="link" :href="url">{{url}}</a>
                    </div>
                </td>
                <td>
                    <button v-if="item.text" class="btn-save" v-on:click="downloadText(item.id)">Скачать</button>
                </td>
                <td>
                    <div class="g">
                        <div class="golos_group">
                            <div class="likes">
                                <input type="text" class="count r" :placeholder="item.stats.likes" v-model="item.stats.likes">
                                <svg class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.16667 7.50004V17.5H0.833336V7.50004H4.16667ZM7.5 17.5C7.05798 17.5 6.63405 17.3244 6.32149 17.0119C6.00893 16.6993 5.83334 16.2754 5.83334 15.8334V7.50004C5.83334 7.04171 6.01667 6.62504 6.325 6.32504L11.8083 0.833374L12.6917 1.71671C12.9167 1.94171 13.0583 2.25004 13.0583 2.59171L13.0333 2.85837L12.2417 6.66671H17.5C17.942 6.66671 18.366 6.8423 18.6785 7.15486C18.9911 7.46742 19.1667 7.89135 19.1667 8.33337V10C19.1667 10.2167 19.125 10.4167 19.05 10.6084L16.5333 16.4834C16.2833 17.0834 15.6917 17.5 15 17.5H7.5ZM7.5 15.8334H15.025L17.5 10V8.33337H10.175L11.1167 3.90004L7.5 7.52504V15.8334Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="likes">
                                <svg class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.8333 12.5V2.5H19.1667V12.5H15.8333ZM12.5 2.5C12.942 2.5 13.366 2.67559 13.6785 2.98816C13.9911 3.30072 14.1667 3.72464 14.1667 4.16667V12.5C14.1667 12.9583 13.9833 13.375 13.675 13.675L8.19167 19.1667L7.30834 18.2833C7.08334 18.0583 6.94167 17.75 6.94167 17.4L6.96667 17.1417L7.75834 13.3333H2.5C2.05798 13.3333 1.63405 13.1577 1.32149 12.8452C1.00893 12.5326 0.833336 12.1087 0.833336 11.6667V10C0.833336 9.78333 0.875003 9.58333 0.950003 9.39167L3.46667 3.51667C3.71667 2.91667 4.30834 2.5 5 2.5H12.5ZM12.5 4.16667H4.975L2.5 10V11.6667H9.81667L8.875 16.1L12.5 12.475V4.16667Z" fill="black"/>
                                </svg>
                                <input type="text" class="count" :placeholder="item.stats.dislikes" v-model="item.stats.dislikes">
                            </div>
                        </div>
                        <button class="btn-save" v-on:click="setlikes(item.id, item.stats.likes, item.stats.dislikes)">Сохранить</button>
                    </div>
                </td> 
                <td>
                    <div style="display: flex; gap: 5px">
                        <div class="status" :class="{'success': item.status === 1}" v-on:click="setStatus(1, item.id)">Одобрено</div>
                        <div class="status" :class="{'done': item.status === 2}" v-on:click="setStatus(2, item.id)">Спето</div>
                        <div class="status" :class="{'err': item.status === 3}" v-on:click="setStatus(3, item.id)">Отказ</div>
                        <div class="status err"  v-on:click="setStatus(4, item.id)">Удалить</div>
                    </div>
                </td> 
            </tr>
            
            <tr v-if="searchQuery" v-for="(item, index) in filterPaginatedData" :key="index">
                <td>{{item.name}}</td> 
                <td style="display: flex; align-items: center; gap: 10px">
                    <img class="video_img" :src="item.img" alt="">
                    <a class="link" :href="item.url">{{item.url}}</a>
                </td> 
                <td>
                    <div v-for="(url, index) in item.social_url" :key="index" style="max-width: 100px; overflow: hidden">
                        <a class="link" :href="url">{{url}}</a>
                    </div>
                </td>
                <td>
                    <button v-if="item.text" class="btn-save" v-on:click="downloadText(item.id)">Скачать</button>
                </td>
                <td>
                    <div class="g">
                        <div class="golos_group">
                            <div class="likes">
                                <input type="text" class="count r" :placeholder="item.stats.likes" v-model="item.stats.likes">
                                <svg class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.16667 7.50004V17.5H0.833336V7.50004H4.16667ZM7.5 17.5C7.05798 17.5 6.63405 17.3244 6.32149 17.0119C6.00893 16.6993 5.83334 16.2754 5.83334 15.8334V7.50004C5.83334 7.04171 6.01667 6.62504 6.325 6.32504L11.8083 0.833374L12.6917 1.71671C12.9167 1.94171 13.0583 2.25004 13.0583 2.59171L13.0333 2.85837L12.2417 6.66671H17.5C17.942 6.66671 18.366 6.8423 18.6785 7.15486C18.9911 7.46742 19.1667 7.89135 19.1667 8.33337V10C19.1667 10.2167 19.125 10.4167 19.05 10.6084L16.5333 16.4834C16.2833 17.0834 15.6917 17.5 15 17.5H7.5ZM7.5 15.8334H15.025L17.5 10V8.33337H10.175L11.1167 3.90004L7.5 7.52504V15.8334Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="likes">
                                <svg class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.8333 12.5V2.5H19.1667V12.5H15.8333ZM12.5 2.5C12.942 2.5 13.366 2.67559 13.6785 2.98816C13.9911 3.30072 14.1667 3.72464 14.1667 4.16667V12.5C14.1667 12.9583 13.9833 13.375 13.675 13.675L8.19167 19.1667L7.30834 18.2833C7.08334 18.0583 6.94167 17.75 6.94167 17.4L6.96667 17.1417L7.75834 13.3333H2.5C2.05798 13.3333 1.63405 13.1577 1.32149 12.8452C1.00893 12.5326 0.833336 12.1087 0.833336 11.6667V10C0.833336 9.78333 0.875003 9.58333 0.950003 9.39167L3.46667 3.51667C3.71667 2.91667 4.30834 2.5 5 2.5H12.5ZM12.5 4.16667H4.975L2.5 10V11.6667H9.81667L8.875 16.1L12.5 12.475V4.16667Z" fill="black"/>
                                </svg>
                                <input type="text" class="count" :placeholder="item.stats.dislikes" v-model="item.stats.dislikes">
                            </div>
                        </div>
                        <button class="btn-save" v-on:click="setlikes(item.id, item.stats.likes, item.stats.dislikes)">Сохранить</button>
                    </div>
                </td> 
                <td>
                    <div style="display: flex; gap: 5px">
                        <div class="status" :class="{'success': item.status === 1}" v-on:click="setStatus(1, item.id)">Одобрено</div>
                        <div class="status" :class="{'done': item.status === 2}" v-on:click="setStatus(2, item.id)">Спето</div>
                        <div class="status" :class="{'err': item.status === 3}" v-on:click="setStatus(3, item.id)">Отказ</div>
                        <div class="status err"  v-on:click="setStatus(4, item.id)">Удалить</div>
                    </div>
                </td> 
            </tr>


        </table>
        <div class="pagination">
            <div v-on:click="pag(1)" class="pagination__btn">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.6667 9.33335L12 16L18.6667 22.6667" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="pagination__num">
                {{this.paginationList}}
            </div>
            ...
            <div class="pagination__num">
                {{this.paginationMax}}
            </div>
            <div v-on:click="pag(2)" class="pagination__btn">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3333 22.6666L20 16L13.3333 9.33331" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import action from '../services/main.js'
import { useAuthStore } from '../store/authStore.js'

export default {
    name: "OffersAuthorPage",
    data() {
        return {
            datas: [],
            paginationList: 1,
            paginatedData: [],
            paginationMax: 0,
            paginationCount: 10,
            loaderDisplay: 'none',
            sort: 0,
            searchQuery: '',
        }
    },
    methods: {
        pag(act) {
            switch (act) {
                case 1:
                    if(this.paginationList == 1) return;
                    this.paginationList--;
                    this.sortActive = true;
                    break;
                case 2:
                    if((this.datas.length / this.paginationCount) <= this.paginationList) return;
                    this.paginationList++;
                    this.sortActive = true;
                    break;
                default:
                    break;
            }

            let a = (this.paginationList - 1) * this.paginationCount;
            let b = this.paginationCount + a;
            this.paginatedData = [];

            this.loaderDisplay = 'flex'

            setTimeout(()=>{
                for (let i = a; i < b; i++) {
                    if(this.datas[i]) {
                        this.paginatedData.push(this.datas[i]);
                    }
                }
                this.loaderDisplay = 'none'
                this.sortActive = false;
            }, 200)
        },
        srt() {
            if(this.sort) {
                this.datas = this.datas.sort((a, b) => a.stats.likes - b.stats.likes);
                this.sort = !this.sort;
            } else {
                this.datas = this.datas.sort((a, b) => b.stats.likes - a.stats.likes);
                this.sort = !this.sort; 
            }

            const filteredArr = this.datas.filter(item => item.status !== 2);
            const itemsWithKey = this.datas.filter(item => item.status === 2);
            this.datas = filteredArr.concat(itemsWithKey);
            
            let a = (this.paginationList - 1) * this.paginationCount;
            let b = this.paginationCount + a;
            this.paginatedData = [];

            this.loaderDisplay = 'flex'

            setTimeout(()=>{
                for (let i = a; i < b; i++) {
                    if(this.datas[i]) {
                        this.paginatedData.push(this.datas[i]);
                    }
                }
                this.loaderDisplay = 'none'
            }, 200)
        },
        setStatus(status, id) {
            action.setStatus(status, id).then(res => {
                if(res.data.code == 200) {
                    window.location.reload();
                }
                if(res.data.code == 401) {
                    const authStore = useAuthStore();

                    authStore.logout();
                    window.location.reload();
                }
            })
        },
        setlikes(a, b, c) {
            action.setlikes(a, b, c).then(res => {
                if(res.data.code == 200) {
                    window.location.reload();
                }
                if(res.data.code == 401) {
                    const authStore = useAuthStore();

                    authStore.logout();
                    window.location.reload();
                }
            })
        },
        downloadText(id) {
            action.musicDownloadText(id).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Текст и аккорды.txt');
                document.body.appendChild(link);
                link.click();
            })
        }
    },
    mounted() {
        action.getMusic(true).then(res => {
            if(res.data.code == 401) {
                const authStore = useAuthStore();

                authStore.logout();
                window.location.reload();
            }else {
                this.datas = res.data;

                const filteredArr = this.datas.filter(item => item.status != 2);
                const itemsWithKey = this.datas.filter(item => item.status == 2);
                this.datas = filteredArr.concat(itemsWithKey);
                    
                let a = this.datas.length / this.paginationCount;
                this.paginationMax = Math.ceil(a);
                for (let i = 0; i < this.paginationCount; i++) {
                    if(this.datas[i]) {
                        this.paginatedData.push(this.datas[i * this.paginationList]);
                    }
                }
            }
        })
    },
    computed: {
        filterPaginatedData() {
            return this.datas.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
        }
    }
}
</script>

<style scoped>
.count {
    width: 100%;
}
.count.r {
    direction: rtl;
}
.psort {
    display: flex; 
    align-items: center;
    gap: 10px;
}
.psort svg {
    background-color:#F8F8F8;
    border-radius: 50%;
    display: flex;
    padding: 5px;
}
.psort svg:hover {
    cursor: pointer;
}
.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.pagination__btn {
    background-color:#F8F8F8;
    border-radius: 50%;
    display: flex;
    padding: 5px;
}
.pagination__btn:hover {
    cursor: pointer;
}
.pagination__num {
    font-size: 16px;
    font-weight: 500;
}
.video_img {
    object-fit:cover;
    min-width:200px;
    height:90px;
    border-radius: 15px;
}
.btn {
    padding: 10px 0;
    background: linear-gradient(95.41deg, #FFD8F6 0%, #FFD8E1 100%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.btn:hover {
    cursor: pointer;
}
.content__table {
    width: 100%;
}
th, td {
    padding: 15px 20px;
    border-bottom: 1px solid #DDDDDD;
}
.row__name {
    text-align: left;
    font-weight: 700;
    font-size: 16px;
}
.link {
    color: #00B3FF;
    text-decoration: underline;
    max-width: 100px!important;
    overflow: hidden;
}

.golos_group {
    display: flex;
    align-items: center;
    gap: 10px;
}
.likes {
    display: flex;
    align-items: center;
    gap: 5px;
}
.status {
    background-color: #EDEDE9;
    padding: 5px 10px;
    border-radius: 15px;
}
.status:hover {
    cursor: pointer;
}
.status.done {
    background-color: #D1E4FF;
}
.status.success {
    background-color: #C7F9CC;
}
.status.err {
    background-color: #FF8FAB;
}

.section {
    margin: 50px 0;
}
.section__name {
    font-size: 32px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}
.section__content {
    max-width: 500px;
    margin: 0 auto;
}
.content__form {
    width: 100%;
}
@media screen and (max-width: 1024px) {
    .content__form {
        overflow-x: auto;
    }
}
.form__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.item__name {
    font-weight: 400;
    font-size: 16px;
}
.item__input {
    background: #F8F8F8;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
}
.g {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.btn-save {
    background-color: #D1E4FF;
    border-radius: 15px;
    padding: 5px 10px;
}
.btn-save:hover {
    cursor: pointer;
}
</style>