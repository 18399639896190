import { defineStore } from 'pinia'
import action from '../services/main.js'

export const useAuthStore = defineStore('auth', {
    state: () => ({ 
        user: null,
        token: localStorage.getItem('token')
    }),
    getters: {
        isAuthenticated: (state) => !!state.token
    },
    actions: {
        async login(username, password) {
            const res = await action.login(username, password);
            localStorage.setItem('token', res.data['token']);

            setTimeout(() => {
                window.location.reload();
            }, 2000);

            return res;
        },
        logout() {
            localStorage.removeItem('token');
            this.user = null;
        },
    }
})