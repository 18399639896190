<template>

    <div class="sname">Действия</div>

    <div class="addblock">
        <div class="content__form">
            <div class="form__item">
                <label class="item__name" for="inptImg">Изображение:</label>
                <div class="drag-container">
                    <input type="file" v-on:change="addImg" id="inptImg"/>
                    <div class="image-placeholder">
                        <span>Нажмите или перетащите изображение сюда</span>
                    </div>
                </div>
            </div>
            <div class="form__item">
                <label class="item__name" for="inptName">Название:</label>
                <input required class="item__input" type="text" v-model="this.add.name" placeholder="Введите название" id="inptName">
            </div>
            <div class="form__item">
                <label class="item__name" for="inptDesc">Описание:</label>
                <input required class="item__input" type="text" v-model="this.add.desciption" placeholder="Введите описание" id="inptDesc">
            </div>
            <div class="form__item">
                <label class="item__name" for="inptUrl">Ссылка:</label>
                <input required class="item__input" type="text" v-model="this.add.url" placeholder="Введите ссылку" id="inptUrl">
            </div>
            <div class="form__item">
                <button type="submit" class="btn" v-on:click="addVideo()">Добавить</button>
            </div>
        </div>
    </div>


    <div style="overflow-x:auto;">

        <table v-if="this.datas" class="content__table">
            <tr class="table__line">
                <th class="row__name">ID</th>
                <th class="row__name">Изображение</th>
                <th class="row__name">Название</th>
                <th class="row__name">Описание</th>
                <th class="row__name">Ссылка</th>
                <th class="row__name">Действие</th>
            </tr>
            <tr v-for="(item, index) in this.datas" :key="index">
                <td>{{item.id}}</td>
                <td>
                    <div v-if="!this.edit.includes(parseInt(item.id))">
                        <img :src="`../img/${item.img}`" class="item__img"> 
                        <!-- <img :src="require(`D:/ospanel/domains/localhost/img/${item.img}`)" class="item__img">  -->
                    </div>
                    <div v-else>
                        <div class="drag-container">
                            <input type="file" v-on:change="handleFileUpload" ref="inputFILE" :id="item.id"/>
                            <div class="image-placeholder">
                                <span>Нажмите или перетащите изображение сюда</span>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="item__inpt" v-if="!this.edit.includes(parseInt(item.id))">
                        {{item.name}}
                    </div>
                    <div v-else>
                        <input class="edit__inpt" type="text" v-model="this.datas[index].name">
                    </div>
                </td>
                <td>
                    <div class="item__inpt" v-if="!this.edit.includes(parseInt(item.id))">
                        {{item.desciption}}
                    </div>
                    <div v-else>
                        <input class="edit__inpt" type="text" v-model="this.datas[index].desciption">
                    </div>
                </td>
                <td>
                    <div class="item__inpt" v-if="!this.edit.includes(parseInt(item.id))">
                        {{item.url}}
                    </div>
                    <div v-else>
                        <input class="edit__inpt" type="text" v-model="this.datas[index].url">
                    </div>
                </td>
                <td>
                    <div v-if="!this.edit.includes(parseInt(item.id))">
                        <svg v-on:click="edt(parseInt(item.id))" class="btns b" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33334 9.33337H8.00001C7.29277 9.33337 6.61449 9.61433 6.11439 10.1144C5.61429 10.6145 5.33334 11.2928 5.33334 12V24C5.33334 24.7073 5.61429 25.3856 6.11439 25.8857C6.61449 26.3858 7.29277 26.6667 8.00001 26.6667H20C20.7073 26.6667 21.3855 26.3858 21.8856 25.8857C22.3857 25.3856 22.6667 24.7073 22.6667 24V22.6667" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.3333 6.66669L25.3333 10.6667M27.18 8.78002C27.7051 8.25489 28.0001 7.54266 28.0001 6.80002C28.0001 6.05738 27.7051 5.34515 27.18 4.82002C26.6549 4.29489 25.9426 3.99988 25.2 3.99988C24.4574 3.99988 23.7451 4.29489 23.22 4.82002L12 16V20H16L27.18 8.78002Z" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        
                        <svg v-on:click="del(parseInt(item.id))" class="btns a" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.3333 12V25.3333H10.6667V12H21.3333ZM19.3333 4H12.6667L11.3333 5.33333H6.66666V8H25.3333V5.33333H20.6667L19.3333 4ZM24 9.33333H7.99999V25.3333C7.99999 26.8 9.19999 28 10.6667 28H21.3333C22.8 28 24 26.8 24 25.3333V9.33333Z" fill="black"/>
                        </svg>
                    </div>
                    <div v-else>
                        <svg v-on:click="save(this.datas[index], item.id)" class="btns b" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33333 25.3333V17.3333H22.6667V25.3333H25.3333V10.4373L21.5627 6.66667H6.66667V25.3333H9.33333ZM5.33333 4H22.6667L28 9.33333V26.6667C28 27.0203 27.8595 27.3594 27.6095 27.6095C27.3594 27.8595 27.0203 28 26.6667 28H5.33333C4.97971 28 4.64057 27.8595 4.39052 27.6095C4.14048 27.3594 4 27.0203 4 26.6667V5.33333C4 4.97971 4.14048 4.64057 4.39052 4.39052C4.64057 4.14048 4.97971 4 5.33333 4ZM12 20V25.3333H20V20H12Z" fill="black"/>
                        </svg>

                        <svg v-on:click="edt(parseInt(item.id))" class="btns a" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 2C8.2 2 2 8.2 2 16C2 23.8 8.2 30 16 30C23.8 30 30 23.8 30 16C30 8.2 23.8 2 16 2ZM16 28C9.4 28 4 22.6 4 16C4 9.4 9.4 4 16 4C22.6 4 28 9.4 28 16C28 22.6 22.6 28 16 28Z" fill="black"/>
                            <path d="M21.4 23L16 17.6L10.6 23L9 21.4L14.4 16L9 10.6L10.6 9L16 14.4L21.4 9L23 10.6L17.6 16L23 21.4L21.4 23Z" fill="black"/>
                        </svg>
                    </div>
                </td>
            </tr>
        </table>

    </div>

</template>

<script>
import { useAuthStore } from '../store/authStore.js';
import action from '../services/main.js'

export default {
    name: "MusicPage",
    data() {
        return {
            datas: null,
            file: null,
            addFile: null,
            add: {
                name: null,
                desciption: null,
                url: null
            },
            edit: [],
        }
    },
    methods: {
        edt(index) {
            let a = this.edit.indexOf(index)

            if(a !== -1) {
                this.edit.splice(a, 1);
            } else {
                this.edit = [];
                this.edit.push(index);
            }
        },
        save(item, id) {
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('id', id);
            formData.append('item', JSON.stringify(item));

            action.adminEditVideo(formData).then(res => {
                if(res.data.code == 200) {
                    this.$notify({
                        title: "Успешно",
                        text: res.data['message'],
                        duration: 2000,
                        type: 'success',
                    }); 
                    setTimeout(a => {
                        window.location.reload()
                    }, 1000)
                }
                if(res.data.code == 400) {
                    this.$notify({
                        title: "Внимание",
                        text: res.data['message'],
                        duration: 2000,
                        type: 'warn',
                    }); 
                }
                if(res.data.code == 401) {
                    const authStore = useAuthStore();

                    authStore.logout();
                    window.location.reload();
                }
            })
        },
        del(id) {
            action.adminDeleteVideo(id).then(res => {
                if(res.data.code == 200) {
                    this.$notify({
                        title: "Успешно",
                        text: res.data['message'],
                        duration: 2000,
                        type: 'success',
                    }); 
                    action.adminGetVideo().then(res => {
                        this.datas = [];
                        this.datas = res.data;
                    })
                }
                if(res.data.code == 400) {
                    this.$notify({
                        title: "Внимание",
                        text: res.data['message'],
                        duration: 2000,
                        type: 'warn',
                    }); 
                }
                if(res.data.code == 401) {
                    const authStore = useAuthStore();

                    authStore.logout();
                    window.location.reload();
                }
            })
        },
        handleFileUpload(event) {
            alert("Фото загружено")
            const file = event.target.files[0];
            this.file = file;
        },
        addImg(event) {
            alert("Фото загружено")
            const file = event.target.files[0];
            this.addFile = file;
        },
        addVideo() {
            let formData = new FormData();
            formData.append('file', this.addFile);
            formData.append('item', JSON.stringify(this.add));

            action.adminAddVideo(formData).then(res => {
                if(res.data.code == 200) {
                    this.$notify({
                        title: "Успешно",
                        text: res.data['message'],
                        duration: 2000,
                        type: 'success',
                    }); 

                    this.addFile = null;
                    this.add.name = null;
                    this.add.year = null;
                    this.add.desciption = null;
                }else if(res.data.code == 400) {
                    this.$notify({
                        title: "Внимание",
                        text: res.data['message'],
                        duration: 2000,
                        type: 'warn',
                    }); 
                }else if(res.data.code == 401) {
                    const authStore = useAuthStore();

                    authStore.logout();
                    window.location.reload();
                }else {
                    this.$notify({
                        title: "Ошибка",
                        text: res.data['message'],
                        duration: 2000,
                        type: 'error',
                    }); 
                }
            })
        }
    },
    mounted() {
        action.adminGetVideo().then(res => {
            this.datas = [];
            this.datas = res.data;
        })
    }
}
</script>

<style scoped>
.content__form {
    width: 100%;
}
.form__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.item__name {
    font-weight: 400;
    font-size: 16px;
}
.item__input {
    background: #F8F8F8;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
}
.drag-container {
    position: relative;
    width: 200px;
    height: 112.5px;
}

.image-placeholder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 2px dashed #ccc;
    border-radius: 15px;
}
.image-placeholder span {
    text-align: center;
}

.drag-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.drag-container input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}



.sname {
    font-size: 24px;
    margin-bottom: 20px;
}
.edit__inpt {
    border: 1px solid #DDDDDD;
    padding: 5px 10px;
    border-radius: 15px;
    max-width: 150px;
}
.item__inpt {
    max-width: 150px;
    overflow: hidden;
}
.item__img {
    max-width: 200px;
    border-radius: 15px;
}
.content__table {
    width: 100%;
}
th, td {
    padding: 15px 20px;
    border-bottom: 1px solid #DDDDDD;
}
.row__name {
    text-align: left;
    font-weight: 700;
    font-size: 16px;
}
.btns {
    margin-left: 10px;
    background-color: #F8F8F8;
    border-radius: 50%;
    padding: 5px;
}
.btns:hover {
    cursor: pointer;
}
.btns.a {
    background-color: #FFCFCF;
}
.btns.b {
    background-color: #CFF4FF;
}
.btn {
    padding: 10px 0;
    background: linear-gradient(95.41deg, #FFD8F6 0%, #FFD8E1 100%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
}
.btn:hover {
    cursor: pointer;
}
.addblock {
    max-width: 400px;
}
</style>